import React from "react";
import { Tooltip } from "@mui/material";
import moment from "moment";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import _ from "underscore";
import ExcalatedIcon from "../../assets/images/excalated-icon.png";
import TagsIcon from "../../assets/images/tags-icon.png";
import { getDayDifference, getIcon } from "../../utils/common";
import {
  CardTwoColumnGrid,
  CardGridItem,
  CardIcon,
  CardDueDate,
  StoryPoints,
} from "./styles";

const SecondMetaContainer = ({
  endDate,
  cardDetails,
  handleTooltipClose,
  boardDetails,
}) => {
  const getCalendarIcon = (card) => {
    const d = new Date(card);
    const month = d.getMonth();
    const date = d.getDate();
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return (
      <div id="due-date-icon">
        <CardDueDate
          style={{
            backgroundColor:
              card.state !== 3
                ? Math.sign(getDayDifference(card)) === 1
                  ? "#F44336"
                  : Math.abs(getDayDifference(card)) < endDate
                  ? "#fac80a"
                  : "#e7e7e7"
                : "#e7e7e7",
            color:
              card.state !== 3 && Math.sign(getDayDifference(card)) === 1
                ? "#fff"
                : "black",
          }}
        >
          {monthNames[month]} {date}
        </CardDueDate>
      </div>
    );
  };

  return (
    <CardTwoColumnGrid>
      <CardGridItem>
        {cardDetails.priority === "High" && (
          <div className="tag priority">
            <Tooltip
              title="High priority"
              placement="right"
              onMouseEnter={handleTooltipClose}
            >
              <ExpandLessOutlinedIcon
                style={{
                  color: "#fff",
                }}
              />
            </Tooltip>
          </div>
        )}
        {cardDetails.priority === "Critical" && (
          <div className="tag priority">
            <Tooltip
              title="Critical priority"
              placement="right"
              onMouseEnter={handleTooltipClose}
            >
              <ErrorOutlineIcon
                style={{
                  color: "#fff",
                }}
              />
            </Tooltip>
          </div>
        )}
        {cardDetails.priority === "Low" && (
          <div className="tag lowpriority">
            <Tooltip
              title="Low priority"
              placement="right"
              onMouseEnter={handleTooltipClose}
            >
              <ExpandMoreIcon />
            </Tooltip>
          </div>
        )}
        {cardDetails.state &&
        parseInt(cardDetails.state) === 3 &&
        !boardDetails.settings.done_card_due_date
          ? null
          : cardDetails.due_date &&
            cardDetails.due_date !== "" && (
              <Tooltip
                title={
                  <div>
                    Planned Finish Date:
                    {moment(cardDetails.due_date).format(
                      "DD-MMM-YYYY hh:mm A"
                    )}{" "}
                    {cardDetails.state &&
                    cardDetails.state === 3 &&
                    !boardDetails.settings.done_card_due_date
                      ? null
                      : `(${Math.abs(getDayDifference(cardDetails.due_date))}
                       ${
                         Math.sign(getDayDifference(cardDetails.due_date)) === 1
                           ? "days past due"
                           : "days left"
                       })`}{" "}
                  </div>
                }
                placement="right"
                onMouseEnter={handleTooltipClose}
              >
                {getCalendarIcon(cardDetails.due_date)}
              </Tooltip>
            )}
      </CardGridItem>
      <CardGridItem>
        {cardDetails.category &&
          cardDetails.category !== "" &&
          boardDetails.settings.customIcon && (
            <Tooltip
              title={
                <div>
                  {boardDetails.settings.customicon_fieldname}:{" "}
                  {cardDetails.category}
                </div>
              }
              placement="left"
              onMouseEnter={handleTooltipClose}
            >
              <CardIcon small>
                {getIcon(cardDetails.category, boardDetails.settings.icons)}
              </CardIcon>
            </Tooltip>
          )}
        {cardDetails.blocked && (
          <Tooltip
            title={
              <div>
                Reason:
                {cardDetails.blocked_reason}
              </div>
            }
            placement="left"
            onMouseEnter={handleTooltipClose}
          >
            <CardIcon small>
              <img src={ExcalatedIcon} alt="blocked-icon" />
            </CardIcon>
          </Tooltip>
        )}
        {cardDetails.tags.length ? (
          <Tooltip
            title={
              <div>
                Tags:
                {_.uniq(cardDetails.tags).toString()}
              </div>
            }
            placement="right"
            onMouseEnter={handleTooltipClose}
          >
            <CardIcon small>
              <img src={TagsIcon} alt="tags-icon" />
            </CardIcon>
          </Tooltip>
        ) : null}
        {cardDetails.card_size && cardDetails.card_size > 0 ? (
          <Tooltip
            title={
              <div>
                Card Size:
                {cardDetails.card_size}
              </div>
            }
            placement="left"
            onMouseEnter={handleTooltipClose}
          >
            <StoryPoints>{cardDetails.card_size}</StoryPoints>
          </Tooltip>
        ) : null}
      </CardGridItem>
    </CardTwoColumnGrid>
  );
};

export default SecondMetaContainer;
