import React, { useState, Fragment, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Tooltip, Menu, MenuItem } from "@material-ui/core";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import Skeleton from "@mui/material/Skeleton";
/* eslint-disable */
import NestedMenuItem from "material-ui-nested-menu-item";
/* eslint-enable */
import _ from "underscore";
import { toast } from "react-toastify";
import useHover from "../../hooks/useHover";
import AlertDialog from "../../components/alert/alertComponent";
import {
  CardFieldUpdateDialog,
  CardTagsDialog,
  CardAssignDialog,
  CardLinkParentDialog,
  CardMoveDialog,
  CardPlannedDateDialog,
  CreateIssueDialog,
  CardCloneDialog,
} from "../../common/Dialog";
import FirstMetaContainer from "./firstMeta";
import SecondMetaContainer from "./secondMeta";
import {
  getBGColor,
  getFirstSetMetaDataAvailability,
  getSecondSetMetaDataAvailability,
  getContrastColor,
} from "../../utils/common";
import {
  selectedCards,
  unselectCard,
  updateBoard,
  deleteCards,
  updateCards,
  unselectCards,
  deleteSubtasks,
} from "../../redux/actions";
import {
  getSelectedCardsSelector,
  getCardDetailsByCardIdSelector,
  getBoardDetailsByBoardIdSelector,
} from "../../redux/selectors";
import {
  Container,
  CommentContainer,
  CardFragment,
  CardWrapper,
  CardHeader,
  ChildCardHeader,
  CardColor,
  CardTitle,
  ChildCardTitle,
  CardDueDate,
} from "./styles";
import useLongPress from "./useLongPress";
import cardService from "../../services/api/cardService";

const errorConfig = {
  position: "top-center",
  autoClose: false,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
};

const CardView = React.memo(
  ({
    cardDetails,
    boardDetails,
    unlink,
    unlinkCard,
    onSelectCards,
    onUnselectCard,
    allSelectedCards,
    select,
    states,
    parentCardBoard,
    onSaveBoardDetails,
    commentCard,
    deleteCardAction,
    saveCardData,
    onUnselectCards,
    deleteSubtaskAction,
    parentview,
  }) => {
    // initializing states
    const [cardHoverRef, isCardHovered] = useHover();

    const [tooltipOpen, setTooltipOpen] = useState(false);

    const [checkFirstMetaData, setCheckFirstMetaData] = useState(false);

    const [checkSecondMetaData, setCheckSecondMetaData] = useState(false);

    const [openCard, setOpenCard] = useState(false);

    const [isSelected, setIsSelected] = useState(false);

    const [selectedCardsList, setSelectedCardsList] = useState([]);

    const [openField, setOpenField] = useState(false);

    const [openTags, setOpenTags] = useState(false);

    const [openAssign, setOpenAssign] = useState(false);

    const [openParentCard, setOpenParentCard] = useState(false);

    const [openMove, setOpenMove] = useState(false);

    const [openClone, setOpenClone] = useState(false);
    const [openArchive, setOpenArchive] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);

    const [openPlannedDate, setOpenPlannedDate] = useState(false);

    const [field, setField] = useState(null);

    const [openIssueDialog, setOpenIssueDialog] = useState(false);
    const [externalTool, setExternalTool] = useState(null);
    const [endDateConfig, setEndDateConfig] = useState(30);

    const [cardAlert, setCardAlert] = useState({
      moveAlert: false,
      alertMessage: "",
    });

    const [menuState, setMenuState] = useState({
      mouseX: null,
      mouseY: null,
    });

    const [allfields, setAllFields] = React.useState([]);
    document.onkeydown = (evt) => {
      evt = evt || window.event;
      let isEscape = false;
      if ("key" in evt) {
        isEscape = evt.key === "Escape" || evt.key === "Esc";
      } else {
        isEscape = evt.keyCode === 27;
      }
      if (isEscape) {
        const selectedCards = allSelectedCards;
        // selectedCards.map((item) => {
        onUnselectCards(selectedCards);
        // })
        removeClass();
      }
    };

    const [showResSec, setShowResSec] = useState(false);

    useEffect(() => {
      // eslint-disable-next-line valid-typeof
      if (typeof window !== undefined) {
        if (window.innerWidth < 769) {
          setShowResSec(true);
        }
      }
    }, [showResSec]);

    useEffect(() => {
      setTooltipOpen(isCardHovered);
    }, [isCardHovered]);
    useEffect(() => {
      if (cardDetails) {
        const selectedcard = allSelectedCards.filter(
          (card) => card.id.toString() === cardDetails.id.toString()
        );
        if (selectedcard.length) {
          setIsSelected(true);
        }
      }
    }, [allSelectedCards]);

    useEffect(() => {
      if(boardDetails.settings.end_date_config){
        setEndDateConfig(Number(boardDetails.settings.end_date_config))
      }
      if (cardDetails) {
        setCheckFirstMetaData(getFirstSetMetaDataAvailability(cardDetails));
        setCheckSecondMetaData(
          getSecondSetMetaDataAvailability(cardDetails, boardDetails)
        );
      }
      if (boardDetails && boardDetails.settings && cardDetails) {
        if (cardDetails.subtask) {
          setAllFields([
            {
              name: "Title",
              field: "title",
              type: "Text",
            },
            {
              name: "Header",
              field: "header",
              type: "Text",
            },
            {
              name: "Block",
              field: "blocked",
              type: "Textarea",
            },
            {
              name: "Unblock",
              field: "unblocked",
              type: "Textarea",
            },
            {
              name: "Description",
              field: "description",
              type: "Textarea",
            },
            {
              name: "Priority",
              field: "priority",
              type: "Select",
              options: ["Critical", "High", "Normal", "Low"],
            },
            {
              name: "Card Size",
              field: "card_size",
              type: "Number",
            },
            {
              name: "Tags",
              field: "tags",
              type: "Multiselect",
            },
            {
              name: "Planned Dates",
              field: "planned_date",
              type: "Date",
            },
            {
              name: boardDetails.settings.customicon_fieldname,
              field: "category",
              type: "Select",
              options: boardDetails.settings.icons,
            },
            {
              name: "Assigned Users",
              field: "assigned_user",
              type: "Multiselect",
            },
            {
              name: "External Link",
              field: "external_url",
              type: "Text",
            },
            {
              name: "Application Name",
              field: "external_system_name",
              type: "Text",
            },
          ]);
        } else {
          setAllFields([
            {
              name: "Title",
              field: "title",
              type: "Text",
            },
            {
              name: "Header",
              field: "header",
              type: "Text",
            },
            {
              name: "Block",
              field: "blocked",
              type: "Textarea",
            },
            {
              name: "Unblock",
              field: "unblocked",
              type: "Textarea",
            },
            {
              name: "Description",
              field: "description",
              type: "Textarea",
            },
            {
              name: "Priority",
              field: "priority",
              type: "Select",
              options: ["Critical", "High", "Normal", "Low"],
            },
            {
              name: "Card Type",
              options: boardDetails.settings.card_type.filter(
                (item) => item.name.toLowerCase() !== "subtask"
              ),
              field: "card_type",
              type: "Select",
            },
            {
              name: "Card Size",
              field: "card_size",
              type: "Number",
            },
            {
              name: "Tags",
              field: "tags",
              type: "Multiselect",
            },
            {
              name: "Planned Dates",
              field: "planned_date",
              type: "Date",
            },
            {
              name: boardDetails.settings.customicon_fieldname,
              field: "category",
              type: "Select",
              options: boardDetails.settings.icons,
            },
            {
              name: "Assigned Users",
              field: "assigned_user",
              type: "Multiselect",
            },
            {
              name: "External Link",
              field: "external_url",
              type: "Text",
            },
            {
              name: "Application Name",
              field: "external_system_name",
              type: "Text",
            },
          ]);
        }
      }
    }, [cardDetails, boardDetails]);

    useEffect(() => {
      if (!allSelectedCards.length) {
        setIsSelected(false);
      }
    }, [allSelectedCards]);

    const stopEvent = (event, url) => {
      event.preventDefault();
      event.stopPropagation();
      window.open(url, "_blank");
    };
    const getHeaderText = (card, settings) => {
      switch (settings.card_header) {
        case "cardid":
          return card.id;
        case "headertext":
          return card.header;
        case "cardtype":
          return card.card_type;

        default:
          return card.header;
      }
    };
    const getHeader = (card, settings) => (
      <>
        {settings.header_url && settings.header_url !== "" ? (
          <>
            {settings.header_url.indexOf("{id}") >= 0 ? (
              <a
                target="_blank"
                href={settings.header_url.replace("{id}", card.header)}
                onClick={(e) =>
                  stopEvent(e, settings.header_url.replace("{id}", card.header))
                }
                rel="noreferrer"
                style={{
                  color: getContrastColor(
                    getBGColor(boardDetails.settings.card_type, card.card_type)
                  ),
                }}
              >
                <span>{getHeaderText(card, settings)}</span>
              </a>
            ) : (
              <a
                target="_blank"
                href={settings.header_url.replace("{ID}", card.header)}
                onClick={(e) =>
                  stopEvent(e, settings.header_url.replace("{ID}", card.header))
                }
                rel="noreferrer"
                style={{
                  color: getContrastColor(
                    getBGColor(boardDetails.settings.card_type, card.card_type)
                  ),
                }}
              >
                <span>{getHeaderText(card, settings)}</span>
              </a>
            )}
          </>
        ) : (
          <span
            style={{
              color: getContrastColor(
                getBGColor(boardDetails.settings.card_type, card.card_type)
              ),
            }}
          >
            {getHeaderText(card, settings)}
          </span>
        )}
      </>
    );

    const handleTooltipClose = () => {
      setTooltipOpen(false);
    };

    const handleExternalTool = (tool) => {
      setExternalTool(tool);
      setOpenIssueDialog(true);
    };

    const isRightClick = (event) => {
      if (event.ctrlKey || event.metaKey) {
        return true;
      }
      return false;
    };

    const history = useHistory();

    const viewCard = (event) => {
      event.preventDefault();
      const rightClickEvent = isRightClick(event);
      if (!rightClickEvent || unlink) {
        if (parentCardBoard) {
          window.location.href = `/board/${cardDetails.boardid}?cardid=${cardDetails.id}`;
        } else if (cardDetails.subtask) {
          history.push({
            pathname: `/board/${boardDetails.id}`,
            search: `?cardid=${cardDetails.id}`,
            state: {
              subtask: true,
              parent_card: cardDetails.parent_card,
            },
          });
        } else {
          history.push({
            pathname: `/board/${boardDetails.id}`,
            search: `?cardid=${cardDetails.id}`,
            state: {
              parent: !!unlink,
            },
          });
        }
      } else {
        const cardsList = [];
        if (isSelected) {
          onUnselectCard(cardDetails.id);
        } else {
          cardsList.push(cardDetails);
          onSelectCards(cardsList);
        }
        setIsSelected((current) => !current);
      }
    };

    const closeCard = () => {
      setOpenCard(false);
    };

    const handleClick = (event) => {
      event.preventDefault();
      if (!cardDetails.archived && !parentview) {
        const selectedCards = allSelectedCards;
        const foundObj = selectedCards.some(
          (el) => el.id.toString() === cardDetails.id.toString()
        );
        if (foundObj) {
          setMenuState({
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
          });
          setTooltipOpen(false);
          if (!isSelected) {
            setIsSelected((current) => !current);
          }
        } else {
          if (selectedCards.length) {
            selectedCards.map((item) => {
              const element = document.getElementById(item.id);
              if (element) {
                element.classList.remove("card__true");
              }
            });
            onUnselectCards(selectedCards);
          }

          const cardArray = [];
          cardArray.push(cardDetails);
          onSelectCards(cardArray);

          setTooltipOpen(false);
          // setIsSelected(current => !current);

          const element = document.getElementById(cardDetails.id);
          element.classList.add("card__true");
          setMenuState({
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
          });
        }
      }
    };

    const handleMenuClose = () => {
      setMenuState({
        mouseX: null,
        mouseY: null,
      });
    };

    const handleFieldChange = (field, custom) => {
      if (allSelectedCards.length === 0) {
        setCardAlert({
          moveAlert: true,
          alertMessage: `Please select at least one card to update ${field.name} in bulk`,
        });
        return false;
      }
      if (custom) {
        field.custom = true;
      }
      setField(field);
      if (field.name === "Tags") {
        addTags();
      } else if (field.name === "Assigned Users") {
        addAssignedUsers();
      } else if (field.name === "Planned Dates") {
        updatePlannedDate();
      } else {
        setOpenField(true);
      }
    };

    const closeFieldDialog = () => {
      removeClass();
      setOpenField(false);
      handleMenuClose();
    };

    const addTags = () => {
      if (allSelectedCards.length === 0) {
        setCardAlert({
          moveAlert: true,
          alertMessage:
            "Please select at least one card to update tags in bulk",
        });
        return false;
      }
      setOpenTags(true);
      handleMenuClose();
    };

    const closeTagsDialog = () => {
      removeClass();
      setOpenTags(false);
    };

    const addAssignedUsers = () => {
      if (allSelectedCards.length === 0) {
        setCardAlert({
          moveAlert: true,
          alertMessage:
            "Please select at least one card to assign users in bulk",
        });
        return false;
      }
      setOpenAssign(true);
      handleMenuClose();
    };

    const closeAssignDialog = () => {
      removeClass();
      setOpenAssign(false);
    };

    const removeClass = () => {
      if (allSelectedCards.length === 1) {
        const element = document.getElementById(
          parseInt(allSelectedCards[0].id)
        );
        if (element) {
          element.classList.remove("card__true");
        }
      }
    };

    const linkParentCard = () => {
      if (allSelectedCards.length === 0) {
        setCardAlert({
          moveAlert: true,
          alertMessage: "Please select at least one card to link into parent",
        });
        return false;
      }
      setOpenParentCard(true);
      handleMenuClose();
    };

    const closeParentCardDialog = () => {
      removeClass();
      setOpenParentCard(false);
    };
    const deleteCard = () => {
      if (allSelectedCards.length === 0) {
        setCardAlert({
          moveAlert: true,
          alertMessage: "Please select at least one card to move",
        });
        return false;
      }
      setOpenDelete(true);
      handleMenuClose();
    };

    const handleDeleteCards = () => {
      const cards = allSelectedCards;
      const result = cards.map((a) => a.id.toString());
      const input = {};
      input.ids = result;
      cardService.deleteCards(input).then(
        (response) => {
          // cards.forEach((element) => {
          //   if (element.subtask) {
          //     deleteSubtaskAction(element.id)
          //   } else {
          //     deleteCardAction(element.id)
          //   }
          //   toast.success('Selected cards has been deleted successfully')
          // })
          if (cards[0].subtask) {
            deleteSubtaskAction(cards);
            toast.success("Selected subtasks has been deleted successfully");
          } else {
            deleteCardAction(cards);
            toast.success("Selected cards has been deleted successfully");
          }

          onUnselectCards(cards);
          closeDeleteDialog();
        },
        (error) => {
          const errorMessage =
            error.response.data.message ||
            "Something went wrong. Please contact your Administrator!";
          toast.error(errorMessage, errorConfig);
        }
      );
    };

    const handleArchiveCards = () => {
      const cards = allSelectedCards;
      const result = cards.map((a) => a.id.toString());
      const input = {};
      input.ids = result;
      cardService.bulkArchiveCards(input).then(
        (response) => {
          const cardstoarchive = cards;
          cardstoarchive.forEach((element, i) => {
            cardstoarchive[i].active = false;
            cardstoarchive[i].archived = true;
          });
          saveCardData(cardstoarchive);
          onUnselectCards(cards);
          toast.success("Selected cards has been archived successfully");
          closeArchiveDialog();
        },
        (error) => {
          const errorMessage =
            error.response.data.message ||
            "Something went wrong. Please contact your Administrator!";
          toast.error(errorMessage, errorConfig);
        }
      );
    };

    const moveCard = () => {
      if (allSelectedCards.length === 0) {
        setCardAlert({
          moveAlert: true,
          alertMessage: "Please select at least one card to move",
        });
        return false;
      }
      setOpenMove(true);
      handleMenuClose();
    };

    const cloneCard = () => {
      if (allSelectedCards.length === 0) {
        setCardAlert({
          moveAlert: true,
          alertMessage: "Please select at least one card to clone",
        });
        return false;
      }
      setOpenClone(true);
      handleMenuClose();
    };

    const archiveCard = () => {
      if (allSelectedCards.length === 0) {
        setCardAlert({
          moveAlert: true,
          alertMessage: "Please select at least one card to archive",
        });
        return false;
      }
      setOpenArchive(true);
      handleMenuClose();
    };

    const closeMoveDialog = () => {
      removeClass();
      setOpenMove(false);
    };
    const closeArchiveDialog = () => {
      removeClass();
      setOpenArchive(false);
    };

    const closeDeleteDialog = () => {
      removeClass();
      setOpenDelete(false);
    };
    const closeIssueDialog = () => {
      handleMenuClose();
      removeClass();
      setOpenIssueDialog(false);
    };

    const closeCloneDialog = () => {
      if (allSelectedCards.length === 1) {
        const element = document.getElementById(
          parseInt(allSelectedCards[0].id)
        );
        if (element) {
          element.classList.remove("card__true");
        }
      }
      setOpenClone(false);
    };

    const updatePlannedDate = () => {
      if (allSelectedCards.length === 0) {
        setCardAlert({
          moveAlert: true,
          alertMessage:
            "Please select at least one card to update planned start date",
        });
        return false;
      }
      setOpenPlannedDate(true);
      handleMenuClose();
    };

    const showChildCards = () => {
      handleMenuClose();

      if (allSelectedCards.length === 1) {
        const saveData = {
          parentCard: allSelectedCards[0].id.toString(),
        };
        onSaveBoardDetails(boardDetails.id, saveData);
        const selectedCards = allSelectedCards;
        // selectedCards.map((item) => {
        onUnselectCards(selectedCards);
        // })
        removeClass();
      }
    };

    const closePlannedDateDialog = () => {
      removeClass();
      setOpenPlannedDate(false);
    };

    const handleAlertClose = () => {
      setCardAlert({
        moveAlert: false,
      });
    };

    const onLongPress = () => {
      if (allSelectedCards.length === 0 && !unlink) {
        const cardsList = [];
        cardsList.push(cardDetails);
        onSelectCards(cardsList);
        setIsSelected((current) => !current);
      }
    };

    const onClick = () => {
      if (!allSelectedCards.length || unlink) {
        if (parentCardBoard) {
          window.location.href = `/board/${cardDetails.boardid}?cardid=${cardDetails.id}`;
        } else if (cardDetails.subtask) {
          history.push({
            pathname: `/board/${boardDetails.id}`,
            search: `?cardid=${cardDetails.id}`,
            state: {
              subtask: true,
              parent_card: cardDetails.parent_card,
            },
          });
        } else {
          history.push({
            pathname: `/board/${boardDetails.id}`,
            search: `?cardid=${cardDetails.id}`,
            state: {
              parent: !!unlink,
            },
          });
        }
      } else if (allSelectedCards.length > 0) {
        const cardsList = [];
        if (isSelected) {
          onUnselectCard(cardDetails.id);
        } else {
          cardsList.push(cardDetails);
          onSelectCards(cardsList);
        }
        setIsSelected((current) => !current);
      }
    };

    const defaultOptions = {
      shouldPreventDefault: true,
      delay: 500,
    };

    const longPressEvent = useLongPress(onLongPress, onClick, defaultOptions);

    return (
      <>
        {commentCard ? (
          <CommentContainer
            id={cardDetails.id}
            onClick={!showResSec && viewCard}
            className="card "
            ref={cardHoverRef}
            {...(showResSec && longPressEvent)}
          >
            <Tooltip
              title={
                cardDetails.card_type
                  ? `${cardDetails.card_type}: ${cardDetails.title}`
                  : cardDetails.title
              }
              open={tooltipOpen}
            >
              <CardFragment>
                <CardColor
                  style={{
                    background: getBGColor(
                      boardDetails.settings.card_type,
                      cardDetails.card_type
                    ),
                  }}
                />
                <CardWrapper>
                  {cardDetails.header &&
                    boardDetails.settings &&
                    boardDetails.settings.card_header &&
                    boardDetails.settings.card_header !== "noheader" && (
                      <ChildCardHeader
                        onMouseEnter={
                          boardDetails.settings.header_url &&
                          boardDetails.settings.header_url !== ""
                            ? handleTooltipClose
                            : null
                        }
                        style={{
                          background: getBGColor(
                            boardDetails.settings.card_type,
                            cardDetails.card_type
                          ),
                        }}
                      >
                        {getHeader(cardDetails, boardDetails.settings)}
                      </ChildCardHeader>
                    )}
                  {!cardDetails.header &&
                    boardDetails.settings &&
                    boardDetails.settings.card_header &&
                    (boardDetails.settings.card_header === "cardid" ||
                      boardDetails.settings.card_header === "cardtype") && (
                      <ChildCardHeader
                        onMouseEnter={
                          boardDetails.settings.header_url &&
                          boardDetails.settings.header_url !== ""
                            ? handleTooltipClose
                            : null
                        }
                        style={{
                          background: getBGColor(
                            boardDetails.settings.card_type,
                            cardDetails.card_type
                          ),
                        }}
                      >
                        {getHeader(cardDetails, boardDetails.settings)}
                      </ChildCardHeader>
                    )}
                  <ChildCardTitle
                    cardHeader={
                      boardDetails.settings.card_header === "headertext"
                        ? !!cardDetails.header
                        : true
                    }
                    hasFirstSetMetaData={checkFirstMetaData}
                    hasSecondSetMetaData={checkSecondMetaData}
                  >
                    {cardDetails.title}
                  </ChildCardTitle>
                </CardWrapper>
              </CardFragment>
            </Tooltip>
          </CommentContainer>
        ) : (
          <Container
            id={cardDetails.id}
            onContextMenu={!showResSec && handleClick}
            onClick={!showResSec && viewCard}
            className={`card card__${isSelected}
            ${
              boardDetails.parentCard &&
              boardDetails.parentCard.toString() === cardDetails.id.toString()
                ? "card__parentcard"
                : ""
            } card__${
              boardDetails.parentCard &&
              boardDetails.parentCard.toString() !==
                cardDetails.id.toString() &&
              (!cardDetails.parent_array ||
                (cardDetails.parent_array &&
                  !cardDetails.parent_array.includes(
                    parseInt(boardDetails.parentCard)
                  )))
                ? "childcard"
                : ""
            }`}
            ref={cardHoverRef}
            {...(showResSec && longPressEvent)}
          >
            <Tooltip
              title={
                cardDetails.card_type
                  ? `${cardDetails.card_type}: ${cardDetails.title}`
                  : cardDetails.title
              }
              open={tooltipOpen}
            >
              <CardFragment>
                <CardColor
                  style={{
                    background: getBGColor(
                      boardDetails.settings.card_type,
                      cardDetails.card_type
                    ),
                  }}
                />
                <CardWrapper>
                  {cardDetails.header &&
                    boardDetails.settings &&
                    boardDetails.settings.card_header &&
                    boardDetails.settings.card_header !== "noheader" && (
                      <CardHeader
                        onMouseEnter={
                          boardDetails.settings.header_url &&
                          boardDetails.settings.header_url !== ""
                            ? handleTooltipClose
                            : null
                        }
                        style={{
                          background: getBGColor(
                            boardDetails.settings.card_type,
                            cardDetails.card_type
                          ),
                        }}
                      >
                        {getHeader(cardDetails, boardDetails.settings)}
                      </CardHeader>
                    )}
                  {!cardDetails.header &&
                    boardDetails.settings &&
                    boardDetails.settings.card_header &&
                    (boardDetails.settings.card_header === "cardid" ||
                      boardDetails.settings.card_header === "cardtype") && (
                      <CardHeader
                        onMouseEnter={
                          boardDetails.settings.header_url &&
                          boardDetails.settings.header_url !== ""
                            ? handleTooltipClose
                            : null
                        }
                        style={{
                          background: getBGColor(
                            boardDetails.settings.card_type,
                            cardDetails.card_type
                          ),
                        }}
                      >
                        {getHeader(cardDetails, boardDetails.settings)}
                      </CardHeader>
                    )}
                  <CardTitle
                    cardHeader={
                      boardDetails.settings.card_header === "headertext"
                        ? !!cardDetails.header
                        : true
                    }
                    // cardHeader={!!cardDetails.header}
                    hasFirstSetMetaData={checkFirstMetaData}
                    hasSecondSetMetaData={checkSecondMetaData}
                  >
                    {cardDetails.title}
                  </CardTitle>
                </CardWrapper>
                <CardWrapper>
                  {cardDetails.isLoading && cardDetails.statsLoading ? (
                    <Skeleton
                      variant="text"
                      height={24}
                      style={{
                        margin: "0px 6px 5px 10px",
                      }}
                    />
                  ) : (
                    checkFirstMetaData && (
                      <FirstMetaContainer
                        cardDetails={cardDetails}
                        handleTooltipClose={handleTooltipClose}
                      />
                    )
                  )}
                  {checkSecondMetaData && (
                    <SecondMetaContainer
                      endDate = {endDateConfig}
                      cardDetails={cardDetails}
                      handleTooltipClose={handleTooltipClose}
                      boardDetails={boardDetails}
                    />
                  )}
                </CardWrapper>
                {unlink && (
                  <div
                    className="unlink-icon"
                    onClick={(e) => unlinkCard(e, cardDetails.id)}
                    id="unlink-card"
                    style={{
                      pointerEvents:
                        boardDetails.userPermission &&
                        boardDetails.userPermission.permission.includes(
                          "card_edit"
                        )
                          ? "visible"
                          : "none",
                    }}
                  >
                    <Tooltip
                      title="Unlink Card"
                      placement="right"
                      onMouseEnter={handleTooltipClose}
                    >
                      <LinkOffIcon
                        style={{
                          color: "#fff",
                        }}
                      />
                    </Tooltip>
                  </div>
                )}
              </CardFragment>
            </Tooltip>
          </Container>
        )}
        <ClickAwayListener onClickAway={handleMenuClose}>
          <Menu
            // keepMounted
            open={menuState.mouseY !== null}
            onClose={handleMenuClose}
            onClick={handleMenuClose}
            anchorReference="anchorPosition"
            anchorPosition={
              menuState.mouseY !== null && menuState.mouseX !== null
                ? {
                    top: menuState.mouseY,
                    left: menuState.mouseX,
                  }
                : undefined
            }
            anchorOrigin={
              showResSec && {
                vertical: "top",
                horizontal: "center",
              }
            }
            transformOrigin={
              showResSec && {
                vertical: "top",
                horizontal: "center",
              }
            }
            className="cardDropdown"
          >
            {boardDetails.userPermission &&
            boardDetails.userPermission.permission.includes("card_edit") ? (
              <div>
                {!cardDetails.subtask && (
                  <MenuItem onClick={linkParentCard} id="link-parent-card">
                    Link Parent Card
                  </MenuItem>
                )}
                {!cardDetails.subtask && (
                  <MenuItem onClick={moveCard} id="move">
                    Move
                  </MenuItem>
                )}
                <MenuItem onClick={cloneCard} id="clone">
                  Clone
                </MenuItem>
                {boardDetails.settings.archive_cards_manually &&
                  !cardDetails.subtask && (
                    <MenuItem onClick={archiveCard} id="archive">
                      Archive
                    </MenuItem>
                  )}
                {/* <MenuItem onClick={updatePlannedDate}>Update Planned Date</MenuItem> */}
                {allSelectedCards.length === 1 &&
                boardDetails.externalTools &&
                _.where(boardDetails.externalTools, {
                  active: true,
                }).length > 0 ? (
                  <NestedMenuItem
                    label="Create External Link"
                    parentMenuOpen={menuState.mouseY !== null}
                    style={{ display: "flex", "flex-direction": "row" }}
                  >
                    {boardDetails.externalTools &&
                      _.where(boardDetails.externalTools, {
                        active: true,
                      }).map((item) => (
                        <MenuItem onClick={() => handleExternalTool(item)}>
                          {item.name}
                        </MenuItem>
                      ))}
                  </NestedMenuItem>
                ) : null}

                <NestedMenuItem
                  label="Update Fields"
                  id="update_fields"
                  parentMenuOpen={menuState.mouseY !== null}
                  style={{ display: "flex", "flex-direction": "row" }}
                >
                  {allfields.map((item) => (
                    <MenuItem
                      onClick={() => handleFieldChange(item)}
                      id={`update_fields${item.name}`}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
                  {/* <MenuItem onClick={addTags}>Add Tags</MenuItem>
                  <MenuItem onClick={addAssignedUsers}>Assign</MenuItem> */}
                  {boardDetails.custom &&
                  boardDetails.custom !== null &&
                  boardDetails.custom.length ? (
                    <NestedMenuItem
                      label="Custom Fields"
                      id="custom_fields"
                      parentMenuOpen={menuState.mouseY !== null}
                      style={{ display: "flex", "flex-direction": "row" }}
                    >
                      {boardDetails.custom.map(
                        (custom) =>
                          custom.show && (
                            <MenuItem
                              // eslint-disable-next-line max-len
                              onClick={() =>
                                handleFieldChange(custom, "custom")
                              }
                              id={`custom_fields${custom.name}`}
                            >
                              {custom.name}
                            </MenuItem>
                          )
                      )}
                    </NestedMenuItem>
                  ) : null}
                </NestedMenuItem>

                {cardDetails.childcard_total &&
                parseInt(cardDetails.childcard_total) > 0 &&
                allSelectedCards.length === 1 ? (
                  <MenuItem onClick={showChildCards}>
                    Highlight Child Cards
                  </MenuItem>
                ) : null}
                {boardDetails.settings.card_delete && (
                  <MenuItem onClick={deleteCard} id="delete">
                    Delete
                  </MenuItem>
                )}
              </div>
            ) : (
              <div>
                {cardDetails.childcard_total &&
                parseInt(cardDetails.childcard_total) > 0 &&
                allSelectedCards.length === 1 ? (
                  <MenuItem onClick={showChildCards}>
                    Highlight Child Cards
                  </MenuItem>
                ) : null}

                {parseInt(cardDetails.childcard_total) === 0 && (
                  <div
                    style={{
                      color: "red",
                      textAlign: "center",
                      margin: "10px",
                    }}
                  >
                    You have view only access to this board
                  </div>
                )}
              </div>
            )}
          </Menu>
        </ClickAwayListener>
        <AlertDialog
          handleClose={handleAlertClose}
          alertopen={cardAlert.moveAlert}
          message={cardAlert.alertMessage}
          key="alert"
          title="Alert"
        />

        {openField && (
          <CardFieldUpdateDialog
            open={openField}
            field={field}
            boardData={boardDetails}
            boardId={cardDetails.boardid}
            close={closeFieldDialog}
            cards={allSelectedCards}
            unselectCards={onUnselectCards}
          />
        )}
        {openTags && (
          <CardTagsDialog
            open={openTags}
            close={closeTagsDialog}
            tags={boardDetails.tags}
            cards={allSelectedCards}
            unselectCards={onUnselectCards}
            board={boardDetails}
          />
        )}
        {openAssign && (
          <CardAssignDialog
            open={openAssign}
            close={closeAssignDialog}
            boardId={cardDetails.boardid}
            cards={allSelectedCards}
            unselectCards={onUnselectCards}
          />
        )}
        {openParentCard && (
          <CardLinkParentDialog
            open={openParentCard}
            close={closeParentCardDialog}
            boardId={cardDetails.boardid}
            cards={allSelectedCards}
            unselectCards={onUnselectCards}
          />
        )}
        {openMove && (
          <CardMoveDialog
            open={openMove}
            close={closeMoveDialog}
            boardDetails={boardDetails}
            boardId={cardDetails.boardid}
            cards={allSelectedCards}
            unselectCards={onUnselectCards}
          />
        )}
        {openDelete && (
          <AlertDialog
            handleClose={closeDeleteDialog}
            alertopen={openDelete}
            message={`Are you sure want to delete the 
            selected ${allSelectedCards.length} cards ?`}
            key="delete-alert"
            title="Delete Cards"
            confirmbutton
            confirmFunc={handleDeleteCards}
          />
        )}
        {openClone && (
          <CardCloneDialog
            open={openClone}
            close={closeCloneDialog}
            boardId={cardDetails.boardid}
            cards={allSelectedCards}
            unselectCards={onUnselectCards}
          />
        )}
        {openArchive && (
          <AlertDialog
            handleClose={closeArchiveDialog}
            alertopen={openArchive}
            message={`Are you sure want to Archive the 
            selected ${allSelectedCards.length} cards ?`}
            key="archive-alert"
            title="Archive Cards"
            confirmbutton
            confirmFunc={handleArchiveCards}
          />
        )}
        {openPlannedDate && (
          <CardPlannedDateDialog
            open={openPlannedDate}
            close={closePlannedDateDialog}
            cards={allSelectedCards}
            unselectCards={onUnselectCards}
          />
        )}

        {openIssueDialog && (
          <CreateIssueDialog
            open={openIssueDialog}
            data={externalTool}
            close={closeIssueDialog}
            boardDetails={boardDetails}
            boardId={cardDetails.boardid}
            cards={allSelectedCards}
            unselectCards={onUnselectCards}
          />
        )}
      </>
    );
  }
);

const mapStateToProps = (state, ownProps) => {
  const allSelectedCards = getSelectedCardsSelector(state);
  let cardDetails;
  let boardDetails;
  if (ownProps.unlink) {
    if (ownProps.parentCardBoard) {
      boardDetails = {
        id: parseInt(ownProps.boardId),
        settings: ownProps.cardDetails.boardsettings,
        userPermission: ownProps.userPermission,
      };
    } else {
      boardDetails = getBoardDetailsByBoardIdSelector(
        state,
        parseInt(ownProps.boardId)
      );
    }
    cardDetails = ownProps.cardDetails;
  } else {
    cardDetails = ownProps.cardDetails;
    boardDetails = ownProps.boardDetails;
  }
  return {
    allSelectedCards,
    cardDetails,
    boardDetails,
  };
};

const mapDispatchToProps = (dispatch, { boardId }) =>
  bindActionCreators(
    {
      onSelectCards: (list) => selectedCards(list),
      onUnselectCard: (id) => unselectCard(id),
      onUnselectCards: (cards) => unselectCards(cards),
      onSaveBoardDetails: (id, savedata) => updateBoard(parseInt(id), savedata),
      deleteCardAction: (cards) => deleteCards(cards),
      deleteSubtaskAction: (cards) => deleteSubtasks(cards),
      saveCardData: (cards) => updateCards(cards),
    },
    dispatch
  );

CardView.displayName = "CardView";
export default connect(mapStateToProps, mapDispatchToProps)(CardView);
