import axios from 'axios'
import axiosInstance from '../../middleware/api'

function cardTimeline (input) {
  return axiosInstance.post('/api/v2/card/timeline', input)
}
function getBoardDetails (boardId, filter) {
  const constFilter = {
    my_cards: false,
    'Assigned User': [],
    Blockers: [],
    'Card Type': [],
    Category: [],
    Priority: [],
    Tags: [],
    title: ''
  }
  return axiosInstance
    .get(`/api/v2/board/kanbanBoardDetails?id=${boardId}`, filter)
}
function getCardCount (boardId) {
  return axiosInstance.get(`/api/v2/lane/cardcount?boardid=${boardId}`)
}
function getUserPermission (boardId, username) {
  return axiosInstance
    .get(`/api/v2/board/userpermission?id=${boardId}&username=${username}`)
}
function getStates () {
  return axiosInstance.get('/api/v2/board/states')
}
function updateLane (input) {
  return axiosInstance.post('/api/v1/board/updatelane', input)
}

function deleteBoard (id) {
  return axiosInstance.delete(`/api/v2/board/board?id=${id}`)
}

function saveBoardLayout (id, payload) {
  return axiosInstance.post(`/api/v2/lane/boardLayout?id=${id}`, payload)
}

function deleteLanes (input) {
  return axiosInstance.post('/api/v1/board/deleteLanes', input)
}
function addLane (input) {
  return axiosInstance.post('/api/v1/lane/lane', input)
}
function updateCardsToLane (input) {
  return axiosInstance.post('/api/v1/board/updateCardsToLane', input)
}
function createCard (input) {
  return axiosInstance.post('/api/v2/card/card', input)
}
function updateCard (input) {
  return axiosInstance.put('/api/v2/card/card', input)
}
function getKanbanBoards (boardId) {
  return axiosInstance.get('/api/v2/board/kanbanBoards')
}
function createBoard (input) {
  return axiosInstance.post('/api/v2/board/board', input)
}
function saveViewPreference (input) {
  return axiosInstance.post('/api/v2/auth/viewpreference', input)
}
function getViewPreference () {
  return axiosInstance.get('/api/v2/auth/viewpreference')
}
function createBoardFromTemplate (input) {
  return axiosInstance.post('/api/v2/board/createboardfromexistingboard', input)
}

function getJsonFromBoard (input) {
  return axiosInstance.post('/api/v1/board/getJsonFromBoard', input)
}
function importLeankit (input) {
  return axiosInstance.post('/api/v1/board/importLeankit', input)
}
function importleankitboard (input) {
  return axiosInstance.post('/api/v2/board/importBoardFromLeanKit', input)
}
function getBoardAdminAndContributor (id) {
  return axiosInstance
    .get(`/api/v2/board/boardadminandcontributor?id=${id}`)
}
function getStateforSubtask (id) {
  return axiosInstance.get(`/api/v1/board/getStateforSubtask?id=${id}`)
}
function getFieldsForFilter (id) {
  return axiosInstance.get(`/api/v1/board/filterfields?id=${id}`)
}
function saveFilter (input) {
  return axiosInstance.post('/api/v2/board/filters', input)
}
function getSavedFilter (id) {
  return axiosInstance
    .get(`/api/v2/board/filters?id=${id}`)
}

function getBoardSettings (boardId) {
  return axiosInstance.get(`/api/v2/board/boarddetails?id=${boardId}`)
}
function getUsersBoardFromLeanKit () {
  return axiosInstance.get('/api/v2/board/getUsersBoardFromLeanKit')
}

const {
  CancelToken
} = axios
let cancel
function checkBoardNameExist (name) {
  if (cancel !== undefined) {
    cancel()
  }
  return axiosInstance.get(`/api/v2/board/boardname?name=${name}`,
    {
      cancelToken: new CancelToken((c) => {
        cancel = c
      })
    })
}
function checkFilterNameExist (name, boardId) {
  if (cancel !== undefined) {
    cancel()
  }
  return axiosInstance
    .get(`/api/v2/board/checkfilternameexist?name=${name}&id=${boardId}`,
      {
        cancelToken: new CancelToken((c) => {
          cancel = c
        })
      })
}
function getFilterDetails (filterid, boardid) {
  return axiosInstance
    .get(`/api/v2/board/filter?filter_id=${filterid}&id=${boardid}`)
}

function updateFilterDetails (input) {
  return axiosInstance.put('/api/v2/board/filter', input)
}
function deleteFilter (filterid, boardid) {
  return axiosInstance
    .delete(`/api/v2/board/filter?filter_id=${filterid}&id=${boardid}`, {
    })
}
function checkAdmin (username) {
  return axiosInstance.post('/api/v2/checkAdmin', {
    username
  })
}
function getSessionTimeoutMinutes () {
  return axiosInstance.get('/api/v2/admin/sessionTimeoutMinutes')
}
function checkLeankitBoardExist (boardId) {
  return axiosInstance.get(`/api/v2/board/checkLeankitBoardExist?id=${boardId}`)
}
function exportCardsForBoard (boardId, filterInput) {
  filterInput = filterInput === null ? {
  } : filterInput
  axiosInstance.defaults.headers.get.Accept =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  return axiosInstance
    .post(`/api/v2/board/excelexportcards?id=${boardId}&filetype=xlsx`,
      filterInput, {
        responseType: 'arraybuffer'
      })
}
/* eslint max-len:0*/
function exportCardComments (boardId, cardId, filterInput) {
  filterInput = filterInput === null ? {
  } : filterInput
  axiosInstance.defaults.headers.get.Accept =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  return axiosInstance
    .post(`/api/v2/board/excelexportcomments?id=${boardId}&cardId=${cardId}&filetype=xlsx`,
      filterInput, {
        responseType: 'arraybuffer'
      })
}
function exportCardStats(boardid) {
  axiosInstance.defaults.headers.get.Accept =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  return axiosInstance.get(`/api/v2/board/cardstats?boardid=${boardid}`, {
    responseType: 'arraybuffer'
  })
}
function exportBoardHistory (boardId) {
  axiosInstance.defaults.headers.get.Accept =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  return axiosInstance
    .get(`/api/v2/board/excelexporthistory?id=${boardId}`, {
      responseType: 'arraybuffer'
    })
}
function createJWToken (boardId, whatExport) {
  return axiosInstance
    .get(`/api/v2/board/createJWToken?boardId=${boardId}&export=${whatExport}`)
}
function getActivityStream (boardId) {
  return axiosInstance.get(`/api/v2/board/activitystream?id=${boardId}`)
}
// download Import Cards template
function downloadImportCardsTemplate (boardid) {
  return axiosInstance
    .get(`/api/v2/board/downloadtemplateforimportcards?id=${boardid}`, {
      responseType: 'arraybuffer'
    })
}
function bulkMoveUpdate (input) {
  return axiosInstance.put('/api/v2/card/bulkmove', input)
}
function archiveCard (input) {
  return axiosInstance.put('/api/v2/card/archivecard', input)
}
function unArchiveCard (input) {
  return axiosInstance.put('/api/v2/card/unarchivecard', input)
}
function saveRecentAndFavoriteBoard (input) {
  return axiosInstance.post('/api/v2/board/recentandfavoriteboard', input)
}
function getBoardWEAccess () {
  return axiosInstance.get('/api/v2/board/boardswithwriteaccess')
}
function checkCardExists (cardid) {
  return axiosInstance.get(`/api/v2/card/exists?id=${cardid}`)
}
function checkQuickFilter (filterId, id) {
  return axiosInstance
    .get(`/api/v2/board/isquickfilterexists?filter_id=${filterId}&id=${id}`)
}
function makeFilterAsQuickFilter (input) {
  return axiosInstance.post('/api/v2/board/quickfilter', input)
}
function removeFromQuickFilter (filterId, id) {
  return axiosInstance
    .delete(`/api/v2/board/quickfilter?filter_id=${filterId}&id=${id}`)
}
function getQuickFilter (boardid) {
  return axiosInstance.get(`/api/v2/board/quickfilter?id=${boardid}`)
}
/** function updateTags (input) {
  return axiosInstance.put('/api/v2/card/tags', input)
}
function updatePriority (input) {
  return axiosInstance.put('/api/v2/card/bulkpriority', input)
}
function addAssignee (input) {
  return axiosInstance.put('/api/v2/card/assignee', input)
} */
function addParentCard (input) {
  return axiosInstance.put('/api/v2/card/bulkparentcard', input)
}
function updatePlannedStartAndFinishDate (input) {
  return axiosInstance.put('/api/v2/card/bulkplanneddates', input)
}
function laneDescription (input) {
  return axiosInstance.put('/api/v2/lane/description', input)
}

function getKanbanBoardStats (boardid) {
  return axiosInstance.get(`/api/v2/board/kanbanBoardStats?id=${boardid}`)
}

function getBoardAssignedUsers (boardid) {
  return axiosInstance
    .get(`/api/v2/board/kanbanBoardAssignedUsers?id=${boardid}`)
}

function getCardCountAndSizeReportData (input) {
  return axiosInstance.post('/api/v2/reports/cardcountandsize', input)
}

function getAvgCycleTimeForCards (input) {
  return axiosInstance.post('/api/v2/reports/avgcycletimeforcards', input)
}

function getMeanLeadTimeForCards (input) {
  return axiosInstance.post('/api/v2/reports/meanleadtimeforcards', input)
}
function getReproccessingForCards (input) {
  return axiosInstance.post('/api/v2/reports/reprocessedcards', input)
}
function uploadProfilePicture (data) {
  return axiosInstance.post('/api/v2/auth/profilepicture', data)
}

function unlinkParentChildLinks (data) {
  return axiosInstance.put('/api/v2/card/unlink', data)
}

function deleteFileafterdone (fileName) {
  return axiosInstance.delete('/api/v2/attachments/delete?fileName='+fileName)
}

const boardService = {
  deleteFileafterdone,
  cardTimeline,
  getBoardDetails,
  getCardCount,
  getUserPermission,
  getStates,
  updateLane,
  deleteBoard,
  deleteLanes,
  addLane,
  updateCardsToLane,
  createCard,
  updateCard,
  getKanbanBoards,
  createBoard,
  getJsonFromBoard,
  importLeankit,
  getBoardAdminAndContributor,
  getStateforSubtask,
  getFieldsForFilter,
  saveFilter,
  getSavedFilter,
  getBoardSettings,
  checkBoardNameExist,
  getFilterDetails,
  updateFilterDetails,
  deleteFilter,
  checkFilterNameExist,
  checkAdmin,
  getSessionTimeoutMinutes,
  getUsersBoardFromLeanKit,
  importleankitboard,
  checkLeankitBoardExist,
  exportCardsForBoard,
  exportCardComments,
  exportCardStats,
  exportBoardHistory,
  createJWToken,
  getActivityStream,
  createBoardFromTemplate,
  downloadImportCardsTemplate,
  bulkMoveUpdate,
  archiveCard,
  unArchiveCard,
  saveRecentAndFavoriteBoard,
  getBoardWEAccess,
  checkCardExists,
  checkQuickFilter,
  makeFilterAsQuickFilter,
  removeFromQuickFilter,
  getQuickFilter,
  // updateTags,
  // updatePriority,
  // addAssignee,
  addParentCard,
  updatePlannedStartAndFinishDate,
  laneDescription,
  saveBoardLayout,
  getKanbanBoardStats,
  getBoardAssignedUsers,
  saveViewPreference,
  getViewPreference,
  getCardCountAndSizeReportData,
  uploadProfilePicture,
  getAvgCycleTimeForCards,
  getMeanLeadTimeForCards,
  getReproccessingForCards,
  unlinkParentChildLinks
}

export default boardService
