import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import "date-fns";
import {
  Button,
  Tabs,
  Tab,
  Typography,
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Grid,
  TextField,
  FormControlLabel,
  Radio,
  RadioGroup,
  Chip,
  Tooltip,
  InputAdornment,
  IconButton,
  FormControl,
  FormLabel,
  FormHelperText,
} from "@mui/material";
import { Autocomplete, Alert } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Checkbox from "@mui/material/Checkbox";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import LoadingOverlay from "react-loading-overlay";
import { SketchPicker } from "react-color";
import cookey from "react-cookies";
import { toast } from "react-toastify";
import AlertDialog from "../../../components/alert/alertComponent";
import boardService from "../../../services/api/boardService";
import boardSettingsService from "../../../services/api/boardSettingsService";
import cardDetailsService from "../../../services/api/cardDetailsService";
import UserAccess from "./userAccess";
import CardTypes from "./cardTypes";
import CustomIcons from "./customIcons";
import {
  updateBoard,
  updateLaneSettings,
  fetchState,
  fetchBoard,
} from "../../../redux/actions";
import {
  getAllLanesFilteredSelector,
  getAllLanesSelector,
  getAllLaneChildrenByLaneIdSelector,
} from "../../../redux/selectors";
import CustomFields from "./customFields";
import CardHeader from "./cardHeader";
import ManageTags from "./manageTags";
import ToolIntegration from "./toolIntegration";
import { SettingsContainer, CloseBtn, BoardColorPicker } from "./styles";
import { getNameInitials, getColor } from "../../../utils/common";
import { parseLaneCardDetails } from "../../../redux/utils";
import { InfoOutlined } from "@mui/icons-material";

const PREFIX = "BoardSettingsDialog";

const classes = {
  table: `${PREFIX}-table`,
  paper: `${PREFIX}-paper`,
  title: `${PREFIX}-title`,
  nodata: `${PREFIX}-nodata`,
  error: `${PREFIX}-error`,
  indicator: `${PREFIX}-indicator`,
};

const Root = styled(
  "clonedChildLanes.length; i += 1) {\r\n          parentChildLanesRelation(clonedChildLanes[i]);\r\n        }\r\n      }\r\n      return updatedParentlane;\r\n    }\r\n    const result = {\r\n      lanes: [],\r\n    };\r\n    newLanesArr = parseLaneCardDetails(parentLanes, result);\r\n  }\r\n  if (parentLanes.length "
)(({ theme }) => ({
  [`& .${classes.table}`]: {
    overflowX: "hidden",
    padding: 20,
  },

  [`& .${classes.paper}`]: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },

  [`& .${classes.title}`]: {
    marginLeft: 20,
  },

  [`& .${classes.nodata}`]: {
    textAlign: "center",
    padding: 50,
  },

  [`& .${classes.error}`]: {
    color: "red",
  },

  [`& .${classes.indicator}`]: {
    backgroundColor: "#3f51b5",
  },
}));

library.add(fas);

const errorConfig = {
  position: "top-center",
  autoClose: false,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

function TabProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const BoardSettings = ({
  openModal,
  handleClose,
  boardDetails,
  updateBoardSettings,
  updateLanes,
  getLanes,
  onBoardFetch,
  refreshBoard,
}) => {
  const [board, setBoard] = useState(JSON.parse(JSON.stringify(boardDetails)));
  const [boardname, setBoardName] = useState(boardDetails.name);
  const [cardDays, setCardDays] = useState(
    boardDetails.settings.card_days || 21
  );
  const [configEndDate, setConfigEndDate] = useState(
    boardDetails.settings.end_date_config || 30
  );
  const [deleteOption, setDeleteOption] = useState(
    boardDetails.settings.card_delete
  );
  const [cloneBoardOption, setCloneBoardOption] = useState(
    boardDetails.settings.clone_board
  );
  const [finishDateOption, setFinishDateOption] = useState(
    boardDetails.settings.done_card_due_date
  );
  const [filterViewAdmin, setFilterViewAdmin] = useState(
    boardDetails.settings.filter_view_admin
  );
  const [manuallyArchive, setManuallyArchive] = useState(
    boardDetails.settings.archive_cards_manually
  );
  const [value, setValue] = useState(0);

  const boardSettingsData = boardDetails.settings;
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [deleteBoardAlert, setDeleteBoardAlert] = useState(false);
  const [loader, setLoader] = useState(false);
  const [deleteItem, setDeleteItem] = useState(null);
  const [deleteMessage, setDeleteMessage] = useState(null);
  const [successAlert, setsuccessAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [lanes, setLanes] = useState([]);
  const [selectedLanes, setSelectedLanes] = useState([]);
  const boardDeleteText = `Clicking the below button makes all board 
  users to not access this board. Ensure if your board is 
  really not required before performing delete board operation.`;

  const [boardInitials, setBoardInitials] = useState(
    boardDetails.settings.initials
      ? boardDetails.settings.initials
      : getNameInitials(boardDetails.name)
  );

  const [boardColor, setBoardColor] = useState(
    boardDetails.settings.color
      ? boardDetails.settings.color
      : getColor(boardDetails.name)
  );

  const [showPicker, setShowPicker] = useState(false);

  useEffect(() => {
    if (openModal) {
      setValue(0);
      setBoardName(boardDetails.name);
      setBoardInitials(
        boardDetails.settings.initials
          ? boardDetails.settings.initials
          : getNameInitials(boardDetails.name)
      );
      setBoardColor(
        boardDetails.settings.color
          ? boardDetails.settings.color
          : getColor(boardDetails.name)
      );
      setLanes(getLanes);
      if (getLanes.length > 0) {
        const selectedlanes1 = getLanes.filter(
          (item) =>
            (item.fullname.toLowerCase().indexOf("finished") === 0 &&
              item.state === 3) ||
            item.settings.card_days
        );
        setSelectedLanes(selectedlanes1);
      }
    }
  }, [openModal]);

  const handleDeleteBoard = () => {
    setDeleteBoardAlert(true);
  };
  const confirmDeleteBoardPrimary = () => {
    setLoader(true);
    boardService.deleteBoard(board.id).then(
      (response1) => {
        setLoader(false);
        setDeleteBoardAlert(false);
        handleClose(boardSettingsData);
        window.location.href = "/boards";
      },
      (error) => {
        const errorMessage =
          error.response.data.message ||
          "Something went wrong. Please contact your Administrator!";
        toast.error(errorMessage, errorConfig);
      }
    );
  };

  const confirmDelete = () => {
    setDeleteAlert(false);
    boardSettingsData[deleteItem.type].splice(deleteItem.index, 1);
    const input = {
      settings: boardSettingsData,
      boardId: board.id,
      boardName: boardname,
    };
    boardSettingsService.updateBoardSettings(input).then(
      (response) => {
        setBoard({
          ...board,
          settings: boardSettingsData,
        });
        setDeleteItem(null);
      },
      (error) => {
        const errorMessage =
          error.response.data.message ||
          "Something went wrong. Please contact your Administrator!";
        toast.error(errorMessage, errorConfig);
      }
    );
  };
  const handleLaneChange = (value) => {
    const value1 = value;
    const selectedLaneids = [];
    value1.map((item) => {
      selectedLaneids.push(item.id);
    });
    lanes.map((item) => {
      if (
        item.state === 3 &&
        item.fullname.toLowerCase().indexOf("finished") === 0 &&
        !selectedLaneids.includes(item.id)
      ) {
        value1.push(item);
      }
    });
    setSelectedLanes(value1);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleDateChange = (value) => {
    setConfigEndDate(value);
  };
  const saveChange = (type) => {
    const lanesArr = JSON.parse(JSON.stringify(getLanes));
    const filterchange =
      boardSettingsData.filter_view_admin !== filterViewAdmin;
    boardSettingsData.card_delete = deleteOption;
    boardSettingsData.clone_board = cloneBoardOption;
    boardSettingsData.done_card_due_date = finishDateOption;
    boardSettingsData.filter_view_admin = filterViewAdmin;
    boardSettingsData.archive_cards_manually = manuallyArchive;
    boardSettingsData.initials = boardInitials;
    boardSettingsData.color = boardColor;
    boardSettingsData.end_date_config = configEndDate
    if (cardDays && cardDays !== "0") {
      boardSettingsData.card_days = cardDays;
      const selectedLaneids = [];
      selectedLanes.map((item) => {
        selectedLaneids.push(item.id);
      });
      lanes.map((item, index) => {
        if (selectedLaneids.includes(item.id)) {
          lanes[index].settings.card_days = cardDays;
        } else if (lanes[index].settings.card_days) {
          delete lanes[index].settings.card_days;
        }
      });
    } else {
      boardSettingsData.card_days = 21;
      setSelectedLanes(
        lanes.filter(
          (item) =>
            item.state === 3 &&
            item.fullname.toLowerCase().indexOf("finished") === 0
        )
      );
      lanes.map((item, index) => {
        if (lanes[index].settings.card_days) {
          delete lanes[index].settings.card_days;
        }
      });
    }
    setLoader(false);
    const selectedLaneids = [];
    const updateLaneData = [];
    selectedLanes.map((item) => {
      selectedLaneids.push(item.id);
      const obj = {
        id: item.id,
        settings: item.settings,
      };
      updateLaneData.push(obj);
    });

    const selectedlanesArr = lanesArr.filter(
      (item) =>
        (item.fullname.toLowerCase().indexOf("finished") === 0 &&
          item.state === 3) ||
        item.settings.card_days
    );
    const resultLanesRemove = selectedlanesArr.filter(
      (item1) => !selectedLanes.some((item2) => item2.id === item1.id)
    );

    const removedLanes = [];
    if (resultLanesRemove.length > 0) {
      resultLanesRemove.map((lane) => {
        removedLanes.push(lane.id);
        return lane;
      });
    }

    const input = {
      settings: boardSettingsData,
      boardId: board.id,
      boardName: boardname,
      lane: {
        lane_days: boardSettingsData.card_days,
        id: selectedLaneids,
        removed_id: removedLanes,
      },
    };

    boardSettingsService
      .updateBoardSettings(input)
      .then((response) => {
        setsuccessAlert(true);

        const boardData = JSON.parse(JSON.stringify(board));

        board.name = boardname;
        board.settings = boardSettingsData;
        if (filterchange) {
          boardService.getSavedFilter(board.id).then((filterresponse) => {
            board.savedFilters = filterresponse.data;
            boardService.getQuickFilter(board.id).then((qfilterresponse) => {
              board.quickFilter = qfilterresponse.data;
              updateBoardSettings(parseInt(board.id), board);
            });
          });
        } else {
          updateBoardSettings(parseInt(board.id), board);
        }
        updateLanes(updateLaneData);

        if (
          parseInt(boardData.settings.card_days) !==
            parseInt(boardSettingsData.card_days) ||
          (selectedlanesArr.length > 0 && resultLanesRemove.length > 0) ||
          selectedlanesArr.length !== selectedLanes.length
        ) {
          let constFilter;
          let userN = cookey.load("username");
          userN = userN.toLowerCase();
          const filterContLS = `${userN}_${boardDetails.id}_filterContent`;
          const quickFilterData = localStorage.getItem(filterContLS);
          const filtD = JSON.parse(quickFilterData);
          if (filtD) {
            const filterData = filtD.filterContent;
            const filterUsers = [];
            if (
              filterData["Assigned User"].length > 0 &&
              filterData["Assigned User"][0].id
            ) {
              for (let i = 0; i < filterData["Assigned User"].length; i += 1) {
                filterUsers.push(filterData["Assigned User"][i].name);
              }
              filterData["Assigned User"] = filterUsers;
            }
            constFilter = filterData;
          } else {
            constFilter = {
              my_cards: false,
              "Assigned User": [],
              Blockers: [],
              "Card Type": [],
              Category: [],
              Priority: [],
              Tags: [],
              title: "",
            };
          }
          refreshBoard();
          onBoardFetch(board.id, constFilter);
        }
        setLoader(false);
        setTimeout(() => {
          setsuccessAlert(false);
        }, 5000);
        setBoard({
          ...board,
          settings: boardSettingsData,
          name: board.name,
        });
      })
      .catch((error) => {
        if (error.response.data === "Board Already Exists") {
          setErrorAlert(true);
          setTimeout(() => {
            setErrorAlert(false);
          }, 5000);
        }
      });
  };

  const handleChangeComplete = (color) => {
    setBoardColor(color.hex);
    setShowPicker(false);
  };

  return (
    <div>
      <CloseBtn
        className="closeIcon dialogClose"
        onClick={() => handleClose(boardSettingsData)}
      >
        <Tooltip title="Close">
          <CloseIcon />
        </Tooltip>
      </CloseBtn>
      <SettingsContainer>
        <Tabs
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          className="settingsTabs"
          classes={{
            indicator: classes.indicator,
          }}
        >
          <Tab label="Board Settings" {...TabProps(0)} />
          <Tab label="Board User Access" {...TabProps(1)} />
          <Tab label="Card Types" {...TabProps(2)} />
          <Tab label="Custom Icon" {...TabProps(3)} />
          <Tab label="Fields" {...TabProps(4)} />
          <Tab label="Card Header" {...TabProps(5)} />
          <Tab label="Manage Tags" {...TabProps(6)} />
          <Tab label="Tool Integration" {...TabProps(7)} />
        </Tabs>
        <TabPanel
          value={value}
          index={0}
          style={{
            width: "100%",
            padding: "50px 0px",
            height: "100vh",
            overflowY: "auto",
          }}
          className="tabPanelContainer"
        >
          {successAlert && (
            <Alert severity="success">Board Changes Saved</Alert>
          )}
          {errorAlert && (
            <Alert severity="error">Board name already exists</Alert>
          )}
          <Grid container spacing={1}>
            <Grid
              item
              xs={12}
              md={12}
              style={{
                paddingRight: 10,
              }}
            >
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <div className="cardDetails-group">
                    <label
                      id="boardName-field"
                      className="cardContainerLabel"
                      htmlFor="card-details-82-title"
                    >
                      <span>Board Name</span>
                    </label>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      value={boardname}
                      name="boardName"
                      onChange={(e) => setBoardName(e.target.value)}
                    />
                  </div>
                </Grid>
                <Grid item xs={6} md={3}>
                  <div className="cardDetails-group">
                    <label
                      className="cardContainerLabel"
                      htmlFor="board-initials"
                    >
                      <span>Board Shortcut Name</span>
                    </label>
                    <TextField
                      id="board-initials"
                      variant="outlined"
                      name="boardShortcutName"
                      placeholder="Enter max of 3 characters"
                      value={boardInitials}
                      inputProps={{
                        maxLength: 3,
                      }}
                      onChange={(e) => setBoardInitials(e.target.value)}
                    />
                  </div>
                </Grid>
                <Grid item xs={6} md={3}>
                  <div className="cardDetails-group">
                    <label className="cardContainerLabel" htmlFor="board-color">
                      <span>Board Color</span>
                    </label>
                    <TextField
                      id="board-color"
                      variant="outlined"
                      disabled
                      name="boardColor"
                      placeholder="Pick Color"
                      onClick={() => setShowPicker(!showPicker)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <div
                              className="cardtype"
                              style={{
                                backgroundColor: boardColor,
                              }}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                    {showPicker ? (
                      <BoardColorPicker>
                        <div
                          className="pickerContainer"
                          onClick={() => setShowPicker(false)}
                        />
                        <SketchPicker
                          color={boardColor}
                          onChange={handleChangeComplete}
                        />
                      </BoardColorPicker>
                    ) : null}
                  </div>
                </Grid>
              </Grid>
              <div
                className="cardDetails-group"
                style={{
                  marginTop: 15,
                }}
              >
                <label
                  className="cardContainerLabel"
                  htmlFor="card-details-82-title"
                >
                  <span>
                    Set number of days cards should display on lanes when there
                    is no update on card
                  </span>
                </label>
              </div>
              <Grid container spacing={1}>
                <Grid
                  item
                  xs={12}
                  md={12}
                  style={{
                    maxWidth: "200px",
                    margin: "10px 0px",
                  }}
                >
                  <TextField
                    variant="standard"
                    id="days"
                    type="number"
                    label="Days"
                    value={cardDays}
                    name="card_days"
                    onChange={(e) => setCardDays(e.target.value)}
                  />
                </Grid>
                
                <Grid item xs={12} md={12}>
                  <Autocomplete
                    multiple
                    options={lanes || []}
                    getOptionLabel={(option) =>
                      option.fullname ? option.fullname : option
                    }
                    value={selectedLanes || []}
                    id="boardsettings-lane"
                    onChange={(event, value) => handleLaneChange(value)}
                    disableClearable
                    renderInput={(params) => (
                      <TextField
                        variant="standard"
                        id="boardsettings-lane"
                        {...params}
                        name="lanes"
                        label="Lane"
                      />
                    )}
                    renderTags={(tagValue, getTagProps) =>
                      tagValue.map((option, index) => (
                        <Chip
                          label={option.fullname ? option.fullname : option}
                          {...getTagProps({
                            index,
                          })}
                          disabled={option.state === 3}
                        />
                      ))
                    }
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid
                  item
                  xs={12}
                  md={12}
                  style={{
                    maxWidth: "200px",
                    margin: "10px 0px",
                  }}
                >
                  <FormControl fullWidth>
                    <FormLabel
                      style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        textWrap: 'nowrap',
                        color: '#333', 
                      }}
                    >
                      Configure due date indicator
                      <Tooltip title="If the planned finish date is within the configured days then due date indicator changes to yellow">
                        <IconButton style={{ marginLeft: 5 }}>
                          <InfoOutlined />
                        </IconButton>
                      </Tooltip>
                    </FormLabel>
                    <TextField
                      variant="standard"
                      id="days"
                      type="number"
                      label="Days"
                      value={configEndDate}
                      name="config_date"
                      error={!configEndDate || (configEndDate && configEndDate <= 0)}
                      onChange={(event) => handleDateChange(event.target.value)}
                    />
                    {(!configEndDate  || (configEndDate && configEndDate <= 0)) && (
                      <FormHelperText error style={{whiteSpace: 'nowrap'}}>The Days should be greater than zero</FormHelperText>
                    )}
                  </FormControl>
                  {/* <TextField
                    variant="standard"
                    id="days"
                    type="number"
                    label="No of days for due date indicator"
                    value={configEndDate}
                    name="config_date"
                    onChange={e => {
                      const input = e.target.value;
                      if (input > 0) {
                        setConfigEndDate(input);
                      } else {
                        setConfigEndDate(1);
                      }
                    }}
                  />
                  <Tooltip title="If the planned finish date is within the configured days then due date indicator changes to yellow">
                    <IconButton>
                      <InfoOutlined />
                    </IconButton>
                  </Tooltip> */}
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid
                  item
                  xs={12}
                  // md={8}
                  style={{
                    marginTop: 20,
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="user-delete-card-checkbox"
                        color="secondary"
                        checked={deleteOption}
                        onChange={(e) => setDeleteOption(e.target.checked)}
                        name="Allow users to delete card"
                      />
                    }
                    label="Allow users to delete card"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  // md={8}
                  style={{
                    marginTop: 20,
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="secondary"
                        id="user-clone-board-checkbox"
                        checked={cloneBoardOption}
                        onChange={(e) => setCloneBoardOption(e.target.checked)}
                        name="Allow users to clone board"
                      />
                    }
                    label="Allow users to clone board"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  // md={8}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="planned-finish-date-checkbox"
                        color="secondary"
                        checked={finishDateOption}
                        onChange={(e) => setFinishDateOption(e.target.checked)}
                        name={`Display planned finish date for cards in 
                      Done status categories`}
                      />
                    }
                    label={`Display planned finish date for 
                  cards in Done status categories`}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  // md={8}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="board-admin-saved-filters-checkbox"
                        color="secondary"
                        checked={filterViewAdmin}
                        onChange={(e) => setFilterViewAdmin(e.target.checked)}
                        name={`Allow board admin to view
                      and edit all saved filters`}
                      />
                    }
                    label="Allow board admin to view and edit all saved filters"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  // md={8}
                  style={{
                    marginBottom: 40,
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="archive-cards-checkbox"
                        checked={manuallyArchive}
                        color="secondary"
                        onChange={(e) => setManuallyArchive(e.target.checked)}
                        name="Allow users to manually archive cards"
                      />
                    }
                    label="Allow users to manually archive cards"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  // md={8}
                  style={{
                    marginBottom: 40,
                  }}
                >
                  {board.userPermission &&
                    board.userPermission.permission.includes(
                      "board_delete"
                    ) && (
                      <div>
                        <p>{boardDeleteText}</p>
                        <Button
                          onClick={() => handleDeleteBoard()}
                          color="secondary"
                          variant="contained"
                          id="delete-board"
                        >
                          Delete Board
                        </Button>
                      </div>
                    )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel
          value={value}
          index={1}
          style={{
            width: "100%",
            padding: "50px 0px",
            height: "100vh",
            overflowY: "auto",
          }}
          id="userAccess"
          className="tabPanelContainer"
        >
          <UserAccess board={board} />
        </TabPanel>
        <TabPanel
          value={value}
          index={2}
          style={{
            width: "100%",
            padding: "50px 0px",
            height: "100vh",
            overflowY: "auto",
          }}
          className="tabPanelContainer"
        >
          <CardTypes board={board} />
        </TabPanel>
        <TabPanel
          value={value}
          index={3}
          style={{
            width: "100%",
            padding: "50px 0px",
            height: "100vh",
            overflowY: "auto",
          }}
          className="tabPanelContainer"
        >
          <CustomIcons board={board} />
        </TabPanel>
        <TabPanel
          value={value}
          index={4}
          style={{
            width: "100%",
            padding: "50px 0px",
            height: "100vh",
            overflowY: "auto",
          }}
          className="tabPanelContainer"
        >
          <CustomFields
            board={boardDetails}
            refreshBoard={refreshBoard}
            onBoardFetch={onBoardFetch}
          />
        </TabPanel>
        <TabPanel
          value={value}
          index={5}
          style={{
            width: "100%",
            padding: "50px 0px",
            height: "100vh",
            overflowY: "auto",
          }}
          className="tabPanelContainer"
        >
          <CardHeader board={board} />
        </TabPanel>
        <TabPanel
          value={value}
          index={6}
          style={{
            width: "100%",
            padding: "50px 0px",
            height: "100vh",
            overflowY: "auto",
          }}
          className="tabPanelContainer"
        >
          <ManageTags board={boardDetails} />
        </TabPanel>
        <TabPanel
          value={value}
          index={7}
          style={{
            width: "100%",
            padding: "50px 0px",
            height: "100vh",
            overflowY: "auto",
          }}
          className="tabPanelContainer"
        >
          <ToolIntegration
            board={boardDetails}
            handleClose={handleClose}
            boardSettingsData={boardSettingsData}
          />
        </TabPanel>
      </SettingsContainer>
      {value !== 7 && (
        <DialogActions className="dialogAction">
          {value === 0 && (
            <Button
              id="boardsettings-save-button"
              onClick={() => saveChange("name")}
              disabled={(!configEndDate  || (configEndDate && configEndDate <= 0))}
              color="primary"
              variant="contained"
            >
              Save
            </Button>
          )}
          <Button
            autoFocus
            id="boardsettings-delete-button"
            onClick={() => handleClose(boardSettingsData)}
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      )}
      <AlertDialog
        handleClose={() => setDeleteAlert(false)}
        alertopen={deleteAlert}
        message={deleteMessage}
        key={deleteItem}
        title="Alert"
        confirmbutton
        confirmFunc={() => confirmDelete()}
      />
      <Dialog
        onClose={() => setDeleteBoardAlert(false)}
        aria-labelledby="simple-dialog-title"
        open={deleteBoardAlert}
      >
        <DialogTitle id="alert-dialog-title">Alert</DialogTitle>
        <DialogContent>
          <LoadingOverlay active={loader} spinner text="loading">
            <p
              style={{
                color: "red",
              }}
            >
              Are you sure want to delete this board?
            </p>
          </LoadingOverlay>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => confirmDeleteBoardPrimary()}>Yes</Button>
          <Button onClick={() => setDeleteBoardAlert(false)} color="primary">
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  // const getLanes = getAllLanesFilteredSelector(state);
  let getLanes = [];
  // lane order
  const getalllanes = getAllLanesSelector(state);
  let parentLanes = [];
  const boardLanes = [];
  let newLanesArr = [];
  if (getalllanes.length > 0) {
    parentLanes = getalllanes.filter((lane) => lane.parent_lane === null);
    for (const parentLaneIndex in parentLanes) {
      if (parentLanes[parentLaneIndex]) {
        const parentLaneHierarchy = parentChildLanesRelation(
          parentLanes[parentLaneIndex]
        );
        boardLanes.push(parentLaneHierarchy);
      }
    }
    // eslint-disable-next-line no-inner-declarations
    function parentChildLanesRelation(parentLane) {
      const updatedParentlane = parentLane;
      // Child Lane Details
      const clonedChildLanes = getAllLaneChildrenByLaneIdSelector(
        state,
        parentLane.id
      );
      updatedParentlane.children = clonedChildLanes;
      if (clonedChildLanes && clonedChildLanes.length > 0) {
        for (let i = 0; i < clonedChildLanes.length; i += 1) {
          parentChildLanesRelation(clonedChildLanes[i]);
        }
      }
      return updatedParentlane;
    }
    const result = {
      lanes: [],
    };
    newLanesArr = parseLaneCardDetails(parentLanes, result);
  }
  if (parentLanes.length > 0) {
    const filteredLanes = newLanesArr.lanes.filter(
      (lane) => lane.child_lanes === 0
    );
    const subtaskFilteredLanes = filteredLanes.filter(
      (lane) => !lane.subtask_lane
    );
    getLanes = subtaskFilteredLanes;
  }

  return {
    getLanes,
  };
};

const mapDispatchToProps = (dispatch, { boardId }) =>
  bindActionCreators(
    {
      updateBoardSettings: (id, board) => updateBoard(id, board),
      updateLanes: (lanes) => updateLaneSettings(lanes),
      onBoardFetch: (boardid, filter) => fetchBoard(parseInt(boardid), filter),
      refreshBoard: () => fetchState(),
    },
    dispatch
  );

const BoardSettingsDialog = connect(
  mapStateToProps,
  mapDispatchToProps
)(BoardSettings);

export default BoardSettingsDialog;
